<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('lotto_game.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('lotto_game.result_head') }}{{ title }}</v-card-title>
        <v-card-title> {{ resultText }}</v-card-title>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
            <span class="px-3">{{ $t('search') }}:</span>
            <div style="width: 150px">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchKeyword" outlined dense hide-details />
            </div>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.actionDetail`]="{ item }">
              <v-btn :color="item.status === 'success' ? 'success' : 'error'" class="white--text text-capitalize"
                :disabled="item.status === 'success'" @click="item.status === 'success' ? null : transferReward(item)">
                {{ item.status === 'success' ? this.$t('lotto_game.sended') : this.$t('lotto_game.send') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>

        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="$router.push({ name: 'predict-lotto' })">
            <v-icon>mdi-arrow-left</v-icon>
            <span class="px-3">{{ $t('back') }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      title: '',
      resultText: '',
      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      headers: [
        {
          text: this.$t('lotto_game.user_header1'),
          value: 'id',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.user_header2'),
          value: 'user',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.user_header3'),
          value: 'betThreeDigits',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.user_header4'),
          value: 'betTwoDigits',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('lotto_game.user_header5'),
          value: 'createdAt',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        // {
        //   text: this.$t('lotto_game.user_header6'),
        //   value: 'actionDetail',
        //   width: '150px',
        //   align: 'center',
        //   sortable: false,
        // },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()

    this.getLottoData()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) {
          this.pagination.page = newValue.page
          cont = true
        }
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          this.value = []
          await this.getResultLottoData(newValue.page, newValue.itemsPerPage)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'ทายหวย',
              url: window.location.href,
              detail: 'รายการ ผลออกรางวัลทายหวย',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getLottoData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getLottoList', `id=${id}`)
        let resData = res.data[0]
        this.title = resData.title
        this.resultText = `${this.$t('lotto_game.result3_text')}: ${resData.resultThreeDigitsOne || ''}, ${resData.resultThreeDigitsTwo || ''
          } | ${this.$t('lotto_game.result2_text')}: ${resData.resultTwoDigits || ''}`
        this.loading = false
      } catch (e) { }
    },
    async getResultLottoData(page, row) {
      const id = this.$route.params.id
      try {
        let result = await this.$store.dispatch('getLottoGameUserReward', `page=${page}&rows=${row}&lottoGameId=${id}`)
        let resData = result.data

        for (let i = 0; i < resData.length; i++) {
          this.value.push({
            id: resData[i].id || null,
            lottoId: resData[i].lottoGame.id || null,
            betThreeDigits: resData[i].lottoGameUser.betThreeDigits || '-',
            betTwoDigits: resData[i].lottoGameUser.betTwoDigits || '-',
            userId: resData[i].member.id || null,
            user: resData[i].member.phone || '-',
            createdAt: moment(resData[i].createdAt).format('YYYY-MM-DD HH:mm') || '-',
            status: resData[i].status,
          })
        }
        this.pagination.totalItems = result.count
      } catch (e) { }
    },
    async transferReward(data) {
      const rewardData = {
        memberId: data.userId,
        lottoId: data.lottoId,
      }

      try {
        let result = await this.$store.dispatch('transferLottoRewardToMember', rewardData)
        this.value = []
        this.getResultLottoData(this.pagination.page, this.pagination.rowsPerPage)
      } catch (e) {
        this.$swal.fire({
          icon: 'error',
          title: `${this.$t('lotto_game.send_fail')} [${e.message}]`,
        })
      }
    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'predictLotto')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [
          ...this.headers,
          {
            text: this.$t('lotto_game.user_header6'),
            value: 'actionDetail',
            width: '150px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    async searchKeyword(value) {
      this.loading = true
      this.value = []

      const id = this.$route.params.id
      let result = {}
      if (value) {
        result = await this.$store.dispatch(
          'getLottoGameUserReward',
          `page=1&rows=${this.pagination.rowsPerPage}&search=${value}&lottoGameId=${id}`,
        )
      } else {
        result = await this.$store.dispatch(
          'getLottoGameUserReward',
          `page=1&rows=${this.pagination.rowsPerPage}&lottoGameId=${id}`,
        )
      }
      let resData = result.data
      for (let i = 0; i < resData.length; i++) {
        this.value.push({
          id: resData[i].id || null,
          betThreeDigits: resData[i].lottoGameUser.betThreeDigits || '-',
          betTwoDigits: resData[i].lottoGameUser.betTwoDigits || '-',
          userId: resData[i].member.id || null,
          user: resData[i].member.phone || '-',
          createdAt: moment(resData[i].createdAt).format('YYYY-MM-DD HH:mm') || '-',
          status: resData[i].status,
        })
      }

      this.pagination.totalItems = result.count
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
  },
}
</script>
